import React from 'react';
import { Link } from 'gatsby';
import FluidImage from '../components/fluid-image.js';

const Collection = ({ works }) => {
  const CoverLink = ({ work }) => {
    return (
      <li className='w-full s:w-1/2 md:w-1/3 px-2 flex justify-center align-center'>
        <Link to={work.slug} title={`go to series page for ${work.name}`} className='w-full block flex justify-center align-center flex-col'>
          <FluidImage image={work.cover} className='w-full'/>
        </Link>
      </li>
    );
  };

  return (
    <ul className='flex flex-wrap w-full my-4 mb-16'>
      {works.map(work => <CoverLink work={work} />)}
    </ul>
  );
};

export default Collection;
