import React from 'react';
// import Layout from '../components/layout.js';
import Collection from '../components/collection.js';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const SerialsOrStoriesPage = ({ pageContext }) => {
  let {
    section,
    works
  } = pageContext;

  let bannerData = useStaticQuery(graphql`
  query {
    stories: file(relativePath: { eq: "stories-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    serials: file(relativePath: { eq: "serials-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  }
`)
  let banner = bannerData[section].childImageSharp.fluid;

  return (
    <section className='w-full'>
      <Img
        fluid={banner}
        id={section}
        alt={`hand-drawn banner with ${section} written on it`}
        className='w-1/3 m-auto my-8'
      />
      <Collection works={works}/>
    </section>
  );
};

export default SerialsOrStoriesPage;
